@use 'assets/scss/variables/colors';
@use 'assets/scss/mixins/responsive';

.controller {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10%;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  text-align: center;
  flex-direction: column;
  z-index: 11;
  gap: 16px;
  transform: translateY(250px);

  @include responsive.tablets {
    transform: translateY(250px) scale(1.25);
  }

  @include responsive.desktop {
    transform: translateY(250px);
  }
}

.tendo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-joycon {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  width: 63px;
  padding: 8px;
  transition: background-color 0.5s;

  z-index: 2;
  border-right: 4px solid colors.$dark;
  border-radius: 24px 8px 8px 24px;
  background-color: var(--left-joycon-color);

  transform: translateX(-112px);
}

.right-joycon {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  width: 63px;
  gap: 40px;
  padding: 8px;
  transition: background-color 0.5s;

  z-index: 2;
  background-color: var(--right-joycon-color);
  border-left: 4px solid colors.$dark;
  border-radius: 8px 24px 24px 8px;

  transform: translateX(112px);
}

.btn-joycon-1,
.btn-joycon-2 {
  box-shadow: inset 0 -0.0625em 0.125em rgba(0, 0, 0, 0.75),
    inset 0 0.125em 0.125em -0.0625em rgba(255, 255, 255, 0.4),
    0 0 0.03125em 0.0625em rgba(0, 0, 0, 0.6),
    0 0.0625em 0.25em 0.03125em rgba(0, 0, 0, 0.5);
  cursor: pointer;
  outline: none;

  &:active {
    box-shadow: inset 0 -0.0625em 0.125em rgba(0, 0, 0, 0.75),
      inset 0 0.125em 0.125em -0.0625em rgba(255, 255, 255, 0.4),
      0 0 0.03125em 0.0625em rgba(0, 0, 0, 0.6);
  }
}

button.btn-joycon-1 {
  position: relative;
  background-color: colors.$dark-2;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #0f0f0f;
  }

  &.btn-down {
    .arrow {
      transform: rotate(180deg);
    }
  }
}

button.btn-joycon-2 {
  position: relative;
  background-color: colors.$dark-2;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .letter {
    color: #f0f0f0;
  }
}

.dock-screen-wrapper {
  position: relative;
  text-align: center;
  z-index: 3;
}

.dock-back {
  position: absolute;
  bottom: -8px;
  width: 90%;
  height: 80%;
  background: #313131;
  z-index: 1;
  left: 50%;
  margin-left: -45%;
  border-radius: 8px 8px 0 0;
  border: solid 1px #393939;
  transform: translateY(100%);
}

.dock-front {
  position: absolute;
  bottom: -8px;
  width: 90%;
  height: 70%;
  background: #828282;
  z-index: 3;
  left: 50%;
  margin-left: -45%;
  border-radius: 4px 4px 0 0;
  border: solid 1px #393939;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.7);
  transform: translateY(100%);

  .led {
    border: 1px solid #313131;
    width: 10px;
    height: 5px;
    position: absolute;
    bottom: 5px;
    left: 2px;
  }

  .av-logo {
    position: absolute;
    top: 30%;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 30px;
  }
}


.screen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  border-radius: 4px;
  z-index: 2;
  height: 140px;
  width: 164px;

  .shut-down-overlay {
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .inner-screen {
    width: 80%;
    height: 80%;
    color: #fff;
    display: flex;
    place-items: center;
    justify-content: center;
    position: relative;
    contain: paint;
  }
}
