@font-face {
  font-family: 'Gaban';
  src: local('Gaban'), url('../fonts/gaban.ttf') format('truetype');
}

@font-face {
  font-family: 'Tabardo';
  src: local('Tabardo'), url('../fonts/tabardo.otf') format('opentype');
}

// @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500;600;700&display=swap');
