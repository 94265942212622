@use 'assets/scss/variables/fonts';

body,
button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

h1 {
  font-size: 92px;
}

h2 {
  -webkit-text-stroke-color: #000;
  font-size: 48px;
  margin: 24px auto;
}

h3 {
  font-size: 32px;
  margin: 24px auto;
}
h4 {
  font-size: 24px;
  margin: 16px auto;
}
p {
  font-weight: 500;
  font-size: 18px;
}