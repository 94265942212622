@use 'assets/scss/mixins/responsive';
@use 'assets/scss/mixins/font';
@use 'assets/scss/variables/colors';
@use 'assets/scss/variables/fonts';

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #fff;
  overflow: hidden;
  place-content: center;
  height: 80vh;
  --background: $dark-3;
  --title-color: $dark-2;

  @include responsive.desktop {
    width: 100%;
    height: 100vh;
  }
}

.pointer-ball-placeholder {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--background);

  transition: all 0.5s;
  transform: scale(1);
  z-index: -1;

  @include responsive.tablets {
    width: 175px;
    height: 175px;
  }

  @include responsive.desktop {
    width: 150px;
    height: 150px;
  }

  &.active {
    transform: scale(10);
  }
  &.leave-transition {
    transition-delay: 0.3s;
  }
}

.cover {
  top: 120%;
  position: absolute;
  text-align: center;

  .cover-title-link {
    text-decoration: none;
  }

  .icon-open-marker {
    position: absolute;
    top: -12px;
    right: -20px;
    transform: scale(0.6);

    @include responsive.tablets {
      transform: scale(0.8);
      right: -24px;
    }
  }

  .heading {
    position: relative;
    font-size: 32px;
    margin: 18px;
    color: var(--title-color);
    text-transform: uppercase;
    font-family: fonts.$solid-tabardo;

    @include responsive.tablets {
      font-size: 3.25rem;
    }

    @include responsive.desktop {
      font-size: 2.65rem;
    }

    span {
      position: relative;
    }
  }

  .role {
    color: colors.$dark;
    text-transform: uppercase;
    margin: 18px;
    font-size: 16px;
    font-family: fonts.$outline-gaban;

    @include responsive.tablets {
      @include font.text-xl;
    }

    @include responsive.desktop {
      @include font.text-lg;
    }
  }

  .product {
    color: colors.$dark-2;
    padding: 0 24px;
    @include font.text-sm;

    @include responsive.tablets {
      padding: 0 25%;
      @include font.text-base;
    }

    @include responsive.desktop {
      @include font.text-sm;
    }
  }
}

.ball-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  position: relative;
  gap: 2.4rem;
  margin-top: -30%;

  @include responsive.tablets {
    margin-top: -15%;
  }

  @include responsive.desktop {
    margin-top: -35%;
  }
}

.summary {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  padding: 10vw 12.5vw;
  z-index: 4;
  font-family: fonts.$solid-fredoka;
  font-weight: 500;
  @include font.text-sm;

  @include responsive.tablets {
    @include font.text-xl;
    padding: 10vw 15vw;
  }

  @include responsive.desktop {
    @include font.text-base;
    padding: 48px 9.5vw;
  }
}

.title {
  position: absolute;
  top: 120%;
  z-index: 4;
  opacity: 0;

  @include responsive.desktop {
    top: 150%;
  }

  .btn-title {
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    background-color: transparent;

    .text {
      position: relative;
      @include font.title-outline;
      color: #fff;

      .icon-open-marker {
        position: absolute;
        top: -2px;
        right: -20px;
        transform: scale(0.75);

        @include responsive.tablets {
          right: -24px;
          top: 6px;
          transform: scale(0.8);
        }
      }
    }
  }
}

.bg-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
