@use 'assets/scss/variables/fonts';
@use 'assets/scss/variables/colors';
@use 'assets/scss/mixins/responsive';
@use 'assets/scss/mixins/animation';

.page {
  position: relative;
  background-color: #86baff;
  width: 100vw;
  min-height: 100vh;
  color: #fff;
  overflow: hidden;
}

.foreground {
  position: absolute;
  top: 15%;
  padding: 24px;
  text-align: center;
  width: 100%;
  z-index: 2;

  @include responsive.tablets {
    top: 22.5%;
  }

  @include responsive.desktop {
    top: 15%;
  }

  h2 {
    font-size: 3.65rem;
    font-family: fonts.$solid-fredoka;
    font-weight: 700;
    @include responsive.tablets {
      font-size: 7.5rem;
    }
  }
  h3 {
    color: colors.$dark-3;
    font-family: fonts.$solid-fredoka;
    font-weight: 600;
    margin: 0;
  }
  .contact {
    position: absolute;
    display: block;
    max-width: 20vw;
    max-height: 20vw;
    border-radius: 4px;
    background-color: #fff;
    padding: 12px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.5);
    object-fit: contain;

    img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    &.gmail {
      transform: rotate(10deg);
      bottom: -75px;
      width: 55px;
      height: 55px;
      @include animation.floating(
        $id: linkedin-btn,
        $maxDistance: 10,
        $otherTransforms: rotate(10deg),
        $delay: 0.75s
      );

      @include responsive.tablets {
        width: 75px;
        height: 75px;
        bottom: -100px;
        left: 105px;
      }
      @include responsive.desktop {
        bottom: -50px;
        left: 105px;
      }
    }

    &.linkedin {
      transform: rotate(-5deg);
      bottom: -130px;
      left: 125px;
      width: 65px;
      height: 65px;
      @include animation.floating(
        $id: github-btn,
        $maxDistance: 7,
        $otherTransforms: rotate(-5deg),
        $delay: 0.35s
      );

      @include responsive.tablets {
        left: 275px;
        bottom: -175px;
        width: 105px;
        height: 105px;
      }

      @include responsive.desktop {
        bottom: -180px;
        left: 185px;
      }
    }

    &.twitter {
      transform: rotate(20deg);
      bottom: -195px;
      right: 105px;

      width: 55px;
      height: 55px;
      @include animation.floating(
        $id: twitter-btn,
        $maxDistance: 10,
        $otherTransforms: rotate(20deg),
        $delay: 0.55s
      );

      @include responsive.tablets {
        bottom: -185px;
        right: 225px;

        width: 75px;
        height: 75px;
      }

      @include responsive.desktop {
        bottom: -155px;
        right: 225px;
      }
    }

    &.github {
      transform: rotate(-25deg);
      bottom: -85px;
      right: 35px;
      @include animation.floating(
        $id: gmail-btn,
        $maxDistance: 4,
        $delay: 0s,
        $otherTransforms: rotate(-25deg)
      );

      @include responsive.tablets {
        bottom: -95px;
        right: 75px;

        width: 95px;
        height: 95px;
      }
      @include responsive.desktop {
        bottom: -45px;
        right: 105px;
      }
    }
  }
}

.clouds-light {
  position: absolute;
  bottom: 0;
  background-color: #d6e1f9;
  border-radius: 1em;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.5);
  display: inline-block;
  height: 20%;
  width: 100%;
  filter: drop-shadow(0 2px 3px hsla(0, 0%, 0%, 0.25));

  @include responsive.tablets {
    height: 25%;
  }

  @include responsive.desktop {
    height: 30%;
  }

  .cloud {
    background-color: #d6e1f9;
    content: '';
    border-radius: 100%;
    position: absolute;

    &:nth-child(1) {
      height: 50vw;
      width: 75vw;
      left: -10vw;
      top: -12vw;
      transform: translateY(20%);

      @include responsive.tablets {
        width: 30vw;
        height: 25vw;
        left: -5vw;
        top: -10vw;

        transform: translateY(20%);
      }
    }
    &:nth-child(2) {
      height: 50vw;
      width: 50vw;
      right: 10vw;
      top: -20vw;
      transform: translateY(20%);
      @include responsive.tablets {
        height: 50vw;
        width: 50vw;
        left: 5vw;
        right: unset;
        top: -6vw;
        transform: translateY(10%);
      }
    }
    &:nth-child(3) {
      width: 25vw;
      height: 25vw;
      right: -5vw;
      top: -12.5vw;
      transform: translateY(20%);
      @include responsive.tablets {
        height: 20vw;
        width: 25vw;
        left: 35vw;
        right: unset;
        top: -8vw;
        transform: translateY(15%);
      }
    }
    &:nth-child(4) {
      @include responsive.tablets {
        height: 28vw;
        width: 27vw;
        left: 52.5vw;
        right: unset;
        top: -17vw;
        transform: translateY(30%);
      }

      @include responsive.desktop {
        width: 35vw;
      }
    }
    &:nth-child(5) {
      @include responsive.tablets {
        height: 41vw;
        width: 58vw;
        left: 70.5vw;
        right: 0;
        top: -32vw;
        transform: translateY(30%);
      }

      @include responsive.desktop {
        height: 31vw;
        width: 30vw;
        top: -28vw;
      }
    }
    &:nth-child(6) {
      @include responsive.tablets {
        display: block;
        width: 20vw;
        height: 21vw;
        right: -13vw;
        top: -40.5vw;
        transform: translateY(100%);
      }

      @include responsive.desktop {
        top: -25.5vw;
        transform: translateY(50%);
      }
    }
  }
}

.clouds-dark {
  position: absolute;
  bottom: 0;
  background-color: #c5d9f8;
  border-radius: 1em;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.5);
  display: inline-block;
  height: 7.5%;
  width: 100%;
  filter: drop-shadow(0 2px 3px hsla(0, 0%, 0%, 0.25));
  transform: translateY(100%);

  @include responsive.tablets {
    height: 10%;
  }

  @include responsive.desktop {
    height: 7.5%;
  }

  .cloud {
    background-color: #c5d9f8;
    content: '';
    border-radius: 100%;
    position: absolute;

    &:nth-child(1) {
      height: 40vw;
      width: 40vw;
      left: -5vw;
      top: -18vw;
      transform: translateY(50%);

      @include responsive.tablets {
        width: 30vw;
        height: 35vw;
        left: -10vw;
        top: -20vw;
      }
    }
    &:nth-child(2) {
      height: 75vw;
      width: 75vw;
      right: 3.5vw;
      top: -10vw;
      transform: translateY(50%);
      @include responsive.tablets {
        height: 35vw;
        width: 30vw;
        right: unset;
        left: 5vw;
        top: -12.5vw;
      }
    }
    &:nth-child(3) {
      width: 25vw;
      height: 25vw;
      right: -5vw;
      top: -17.5vw;

      transform: translateY(50%);
      @include responsive.tablets {
        height: 35vw;
        width: 45vw;
        right: unset;
        left: 24vw;
        top: -6vw;
        transform: translateY(10%);
      }
    }
    &:nth-child(4) {
      @include responsive.tablets {
        height: 35vw;
        width: 30vw;
        left: 55vw;
        top: -10vw;
        transform: translateY(10%);
      }
    }
    &:nth-child(5) {
      @include responsive.tablets {
        height: 26vw;
        width: 22.5vw;
        left: 75vw;
        top: -20.5vw;
        transform: translateY(50%);
      }
    }
    &:nth-child(6) {
      @include responsive.tablets {
        width: 45vw;
        height: 40vw;
        right: -35vw;
        top: -30vw;
        transform: translateY(50%);
      }
    }
  }
}
