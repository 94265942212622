@use 'sass:math';

@mixin floating(
  $id: '',
  $maxDistance: math.random(10),
  $delay: 0s,
  $duration: 10s,
  $otherTransforms: null
) {
  @keyframes #{$id}-floating {
    0% {
      transform: translateY(0) $otherTransforms;
    }
    25% {
      transform: translateY(-$maxDistance * 1px) $otherTransforms;
    }
    75% {
      transform: translateY($maxDistance * 1px) $otherTransforms;
    }
    100% {
      transform: translateY(0) $otherTransforms;
    }
  }

  animation: #{$id}-floating ease-in-out alternate infinite $duration $delay;
}
