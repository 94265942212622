@use "assets/scss/variables/breakpoints";

@mixin tablets () {
  @media only screen and (min-width: breakpoints.$md) {
    @content;
  }
}

@mixin desktop () {
  @media only screen and (min-width: breakpoints.$lg){
    @content;
  }
}

@mixin large-screen () {
  @media only screen and (min-width: breakpoints.$xl) {
    @content;
  }
}