@use 'assets/scss/variables/colors';
@use 'assets/scss/mixins/font';
@use 'assets/scss/mixins/responsive';

.story-block {
  color: colors.$dark-3;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;

  @include responsive.tablets {
    padding: 10vw 17.5vw;
  }

  @include responsive.desktop {
    padding: 32px 12.5%;
  }

  p.overview {
    text-align: justify;
    @include font.text-sm;
    
    @include responsive.tablets {
      @include font.text-lg;
    }

    @include responsive.desktop {
      @include font.text-base;
    }
  }

  ul.key-points {
    text-align: left;
    padding-inline-start: 0px;
    @include font.text-sm;
    
    @include responsive.tablets {
      @include font.text-lg;
    }

    @include responsive.desktop {
      @include font.text-base;
    }
  }

  h2 {
    @include font.heading-solid;
  }

  h3 {
    @include font.heading-2-solid;
  }
}

.block-container {
  contain: paint;
  min-height: 100vw;

  @include responsive.desktop {
    min-height: 0;
  }
}
