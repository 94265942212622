@use 'assets/scss/mixins/responsive';
@use 'assets/scss/variables/colors';
@use 'assets/scss/variables/fonts';

.panel-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @include responsive.desktop {
    min-height: 100vh;
  }

  &--primary {
    z-index: 2;
    background-color: transparent;
    height: 80vh;
    @include responsive.desktop {
      width: 100%;
    }
  }

  &--secondary {
    z-index: 1;
    background-color: #fff;
  }
}


.heading {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px 18px;
  font-family: fonts.$outline-gaban;
  color: colors.$dark-2;
  width: 100%;
  display: flex;
  div:nth-child(1) {
    font-size: 1.85rem;

    @include responsive.tablets {
      font-size: 2.85rem;
    }
  }
  div:nth-child(2) {
    font-size: 1rem;
    margin-left: auto;
    margin-top: 4px;

    @include responsive.tablets {
      font-size: 1.55rem;
    }
  }
}