@use 'assets/scss/mixins/responsive';
@use 'assets/scss/mixins/font';
@use 'assets/scss/variables/colors';

.work-nav {
  display: flex;
  position: relative;
  height: 80vh;
  width: 100%;
  overflow: hidden;
  background-color: #fff;

  @include responsive.desktop {
    height: 100vh;
  }
}

.ball {
  position: absolute;
  left: 0;
  top: -150px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: black;
  will-change: transform x y;
}

.slider-wrapper {
  display: flex;
  position: relative;
  gap: 50vw;
  width: 100%;
  will-change: transform x y width;

  @include responsive.desktop {
    gap: 0;
  }
}

.slider-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  z-index: 2;

  will-change: transform x y background;
}

.banner-container {
  flex: 1 0 100vw;
  z-index: 1;

  &.active {
    z-index: 3;
  }

  @include responsive.desktop {
    flex: 1 0 50vw;

    &:nth-child(1) {
      margin-left: 25vw;
    }
  }
}

.next {
  position: fixed;
  top: 80%;
  right: 0;
  background-color: colors.$dark;
  height: 50px;
}

.prev {
  height: 50px;
  position: fixed;
  top: 80%;
  background-color: colors.$dark;
}

.moperty-logo,
.blibli-logo {
  position: absolute;
  align-self: center;
  width: 100px;
  height: 140px;
  z-index: 4;
  filter: drop-shadow(0px 0px 2px rgb(71, 71, 71));

  @include responsive.tablets {
    width: 125px;
    height: 175px;
  }

  @include responsive.desktop {
    width: 100px;
    height: 140px;
  }
}

.logo {
  position: absolute;
  align-self: center;
  z-index: 4;
  filter: drop-shadow(0px 0px 2px rgb(71, 71, 71));

  &-blibli {
    width: auto;
    height: 140px;

    @include responsive.tablets {
      height: 165px;
    }

    @include responsive.desktop {
      height: 140px;
    }
  }

  &-moperty {
    width: 100px;
    height: auto;

    @include responsive.tablets {
      width: 125px;
    }

    @include responsive.desktop {
      width: 100px;
    }
  }

  &-radjastone {
    height: 80px;
    width: auto;

    @include responsive.tablets {
      height: 95px;
    }

    @include responsive.desktop {
      height: 80px;
    }
  }
}
