// https://www.joshwcomeau.com/css/custom-css-reset/

/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html, body, #root {
  height: 100%;
}

button {
  border-style: none;
}